import React from "react"
import { graphql } from 'gatsby';
import { css, Global } from "@emotion/react"
import styled from "@emotion/styled"
import Seo from "~components/Seo"
import { breakpoints } from '~styles/global'
import resolveLink from '~utils/resolveLink'
import FullHeightImage from "../components/FullHeightImage"
import MakerSpotlight from "../components/MakerSpotlight"
import HoveringProduct from "../components/HoveringProduct"
import CloudButton from "~components/CloudButton"
import CrinkleButton from "~components/CrinkleButton"

const { mobile } = breakpoints

const IndexPage = ( data ) => {
  const homepageData = data.data.sanityHomePage._rawContent


  const HoverProductCss = css`
    &:nth-of-type(1){
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
      ${mobile}{
        grid-column: 1 / span 3;
        grid-row: 2 / span 1;
      }
    } 
    &:nth-of-type(2){
      grid-column: 3 / span 1;
      grid-row: 2 / span 1;
      ${mobile}{
        grid-column: 1 / span 3;
        grid-row: 3 / span 1;
      }
    } 
    &:nth-of-type(3){
      grid-column: 2 / span 1;
      grid-row: 3 / span 1;
      ${mobile}{
        grid-column: 1 / span 3;
        grid-row: 4 / span 1;
      }
    } 
    &:nth-of-type(4){
      grid-column: 1 / span 1;
      grid-row: 4 / span 1;
      ${mobile}{
        grid-column: 1 / span 3;
        grid-row: 5 / span 1;
      }
    } 
    &:nth-of-type(5){
      grid-column: 3 / span 1;
      grid-row: 4 / span 1;
      ${mobile}{
        grid-column: 1 / span 3;
        grid-row: 6 / span 1;
      }
    } 
  `

  return (
    <>  
      <Seo
      title={data?.data?.sanitySiteSettings?.siteTitle}
      image={data?.data?.sanitySiteSettings?._rawSocialImage?.asset?.url}
      description={data?.data?.sanitySiteSettings?.metaDescription}
      />
      <div css={css`margin-top: -144px; ${mobile}{ margin-top: -92px;}`}>
        <FullHeightImageDesktop image={homepageData?.image} css={css``}/>
				<FullHeightImageMobile image={homepageData?.mobileImage} css={css``}/>
        <div css={css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          padding: 0 60px;
          ${mobile}{
            padding: 0 20px;
          }
        `}>
          <h1 className={`serif`} css={css`
            grid-row: 1 / span 1;
            grid-column: span 13;
            text-align: center;
            margin-top: 8rem;
            margin-bottom: 6rem;
          `}>{homepageData?.productsTitle}</h1>
          {homepageData?.products.map(tile => (
            <HoveringProduct
              name={tile?.product?.content?.shopify?.title}
              price={tile?.product?.content?.shopify?.price}
              image={tile?.image} 
              css={HoverProductCss} 
              key={tile?._key} 
              link={`${resolveLink('product', tile?.product?.parent?.slug?.current)}?v=${tile.product._id}`}/> 
          ))}
         
        </div>
        <CloudButton to="/shop">
          See more
        </CloudButton>
        <MakerSpotlight />
        <CrinkleButton to="/makers">
          All makers
        </CrinkleButton>
      </div>
    </>
  )
}

const FullHeightImageDesktop = styled(FullHeightImage)`
	display: block;
	${mobile}{display: none;}
`
const FullHeightImageMobile = styled(FullHeightImage)`
	display: none;
	${mobile}{display: block;}
`
export const query = graphql`
  query{
    sanityHomePage {
      _rawContent(resolveReferences: {maxDepth: 10})
    }
    sanitySiteSettings(id: {eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7"}) {
      _rawSocialImage(resolveReferences: {maxDepth: 1})
      metaDescription
      siteTitle
    }
  }
`

export default IndexPage
