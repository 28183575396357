import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Image from '~components/Image'

const FullHeightImage = ({ image, className }) => {
  return (
    <Image 
			className={className}
			asset={image} 
			css={css`.gatsby-image-wrapper {height: 100vh;}`}
		/>
  )
}

FullHeightImage.propTypes = {
    image: PropTypes.object,
}

FullHeightImage.defaultProps = {
}

export default FullHeightImage
