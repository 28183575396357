import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Image from '~components/Image'
import { breakpoints } from '~styles/global'
import { Link } from 'gatsby';

const HoveringProduct = ({ name, image, link, price, className }) => {
  
  const { mobile } = breakpoints

  const [hovering, setHovering] = useState(false)
  
  return (
    <Link
      css={css`
        display: grid;
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
      `}
      className={className}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      to={link}
    >
      <div>
        <div css={css`
            transform: ${ hovering ? 'translateY(0rem)' : 'translateY(2rem)' };
            transition: all 0.2s ease-in-out;
            position: relative;
            z-index: 1;
        `}>
          <Image 
            asset={image} 
            src={image}
            css={css`
              animation: floating 4s infinite;
              transition: all 0.2s ease-in-out;
              width: 100%;
          `}/>
        </div>
        <div css={css`
            position: relative;
            padding-bottom: 15%;
            margin-bottom: 6rem;
        `}>
          <div css={css`
            background: rgba(0, 0, 0, 0.33);
            mix-blend-mode: darken;
            filter: ${ hovering ? 'blur(38px)' : 'blur(32px)' };
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            animation: shadow-pulse 4s infinite;
            transition: all 0.2s ease-in-out;
          `}></div>
        </div>
      </div>
      <div css={css`
        text-align: center;
      `}>
        <h3 css={css`
            margin-bottom: 0.3em;
        `}>
          {name}
        </h3>
        <h3 css={css`
            color: var(--gold);
            transition: opacity 0.3s;
            opacity: ${ hovering ? '1' : '0' };
            ${mobile} {
                opacity: 1;
            }
        `}>
            ${price}
        </h3>
      </div>
    </Link>
  )
}

HoveringProduct.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object,
  price: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
}

HoveringProduct.defaultProps = {
  name: 'Amoln 1945',
  price: 59.00,
  link: '/',
}

export default HoveringProduct
